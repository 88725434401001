// @flow
import * as actions from '../actions/measuredCompany';
import type { Action, State } from '../types';

const intialState = {
  isFetching: false
};

export const measuredCompany = (state: State = intialState, action: Action) => {
  switch (action.type) {
    case actions.READ_MEASURED_COMPANY_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.READ_MEASURED_COMPANY_SUCCESS:
      return {
        measuredCompany: action.payload,
        isFetching: false
      };
    case actions.READ_MEASURED_COMPANY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
