//@flow
import React from 'react';

type Props = {
  title?: any,
  backgroundUrl?: string
};

class PageBanner extends React.Component<Props> {
  render() {
    const { title, backgroundUrl } = this.props;

    const styles = {
      // $FlowFixMe
      backgroundImage: "url('" + backgroundUrl + "')",
      backgroundSize: 'cover',
      textAlign: 'center',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      backgroundPositionX: 'center',
      display: 'flex',
      height: '250px'
    };
    const header = {
      color: '#fff',
      marginLeft: '28px'
    };
    return (
      <div className="ui center aligned top-page-banner" style={styles}>
        <div className="triangle" />
        <div className="ui container">
          {' '}
          <h1 style={header}>{title}</h1>
        </div>
      </div>
    );
  }
}

export default PageBanner;
