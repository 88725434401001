// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI } from '../middleware';

export const LOAD_AWARDS_REQUEST = 'LOAD_AWARDS_REQUEST';
export const LOAD_AWARDS_SUCCESS = 'LOAD_AWARDS_SUCCESS';
export const LOAD_AWARDS_FAILURE = 'LOAD_AWARDS_FAILURE';

const loadAwardsRequest = (): Action => {
  return {
    type: LOAD_AWARDS_REQUEST
  };
};

const loadAwardsSuccess = (page): Action => {
  return {
    type: LOAD_AWARDS_SUCCESS,
    payload: page
  };
};

const loadAwardsFailure = (error): Action => {
  return {
    type: LOAD_AWARDS_FAILURE,
    error
  };
};

export const loadAwards = (
  year: number,
  month: string,
  country: string
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    dispatch(loadAwardsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `/customer-satisfaction-awards/award-groups/?country=${country}&year=${year}&month=${month}`
    });

    if (response instanceof Error) {
      throw response;
    }

    const normalisedPayload = Object.values(response);

    dispatch(loadAwardsSuccess(normalisedPayload));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadAwardsFailure(error));
    return error;
  }
};
