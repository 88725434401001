// @flow
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import { getAuthToken, refreshToken as refresh, logOut } from 'roy-morgan-auth';
import type { Dispatch } from '../types';

// Set BASE_URL via environment variable
export const BASE_URL =
  process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'prod'
    ? 'https://livecms.roymorgan.com'
    : process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'uat'
    ? 'https://livecms.roymorganuat.com'
    : 'https://livecms.roymorgandev.com';

type Props = {
  method: string,
  endpoint: string,
  body?: Object,
  isLiveAPICall?: boolean,
  useToken?: boolean,
};

type APIError = Error & {
  content?: Object,
  code?: string,
};

export const callAPI = async ({
  method,
  endpoint,
  body,
  isLiveAPICall,
  useToken,
}: Props): Promise<any> => {
  try {
    if (isLiveAPICall) {
      return callLiveAPI({ method, endpoint, body, useToken });
    } else {
      return callCMSAPI({ method, endpoint, body });
    }
  } catch (e) {
    if (e.code === 'API') {
      throw e;
    }
    throw new Error('API Error');
  }
};

const callCMSAPI = async ({ method, endpoint, body }: Props) => {
  try {
    const targetUrl = `${BASE_URL}${endpoint}`;

    const response = await fetch(targetUrl, {
      method,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const error: APIError = Error(
        errorResponse ? errorResponse : 'API Error'
      );
      error.content = errorResponse;
      error.code = 'API';
      ///var xxx = JSON.stringify(error);
      throw error;
    }
    const json = await response.json();
    const camelizedJson = camelizeKeys(json, noCamelize);
    return camelizedJson;
  } catch (e) {
    if (e.code === 'API') {
      throw e;
    }
    throw new Error('API Error');
  }
};

const callLiveAPI = async ({ method, endpoint, body, useToken }: Props) => {
  try {
    const targetUrl = `${endpoint}`;
    var activeToken;
    if (useToken === true) {
      activeToken = getAuthToken();
    }
    const token = activeToken;

    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    if (!(typeof token === 'undefined') && token) {
      headers.append('Cache-Control', 'no-cache');
      headers.append('token', 'token');
    }

    const response = await fetch(targetUrl, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const error: APIError = Error(
        errorResponse.errors ? errorResponse.errors[0].message : 'API Error'
      );
      error.code = 'API';
      throw error;
    }
    const json = await response.json();
    const camelizedJson = camelizeKeys(json, noCamelize);
    return camelizedJson;
  } catch (e) {
    if (e.code === 'API') {
      throw e;
    }
    throw new Error('API Error');
  }
};

export const refreshToken = async (dispatch: Dispatch) => {
  if (process.env.NODE_ENV !== 'test') {
    const result = await dispatch(refresh());
    if (!result) {
      dispatch(logOut());
      window.location.href = '/login';
    }
  }
};
