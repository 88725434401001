// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI } from '../middleware';

export const LOAD_AWARD_DETAILS_REQUEST = 'LOAD_AWARD_DETAILS_REQUEST';
export const LOAD_AWARD_DETAILS_SUCCESS = 'LOAD_AWARD_DETAILS_SUCCESS';
export const LOAD_AWARD_DETAILS_FAILURE = 'LOAD_AWARD_DETAILS_FAILURE';

const loadAwardDetailsRequest = (): Action => {
  return {
    type: LOAD_AWARD_DETAILS_REQUEST
  };
};

const loadAwardDetailsSuccess = (awardDetails): Action => {
  return {
    type: LOAD_AWARD_DETAILS_SUCCESS,
    payload: awardDetails
  };
};

const loadAwardDetailsFailure = (error): Action => {
  return {
    type: LOAD_AWARD_DETAILS_FAILURE,
    error
  };
};

export const loadAwardDetails = (
  year: number,
  month: string,
  country: string,
  award_type_id: string,
  award_industry_id: string
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    dispatch(loadAwardDetailsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `/customer-satisfaction-awards/award-details/?country=${country}&year=${year}&month=${month}&award_type_id=${award_type_id}&award_industry_id=${award_industry_id}`
    });

    if (response instanceof Error) {
      throw response;
    }

    const normalisedPayload = Object.values(response);

    dispatch(loadAwardDetailsSuccess(normalisedPayload));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadAwardDetailsFailure(error));
    return error;
  }
};
