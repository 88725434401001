// @flow
import * as actions from '../actions/awardCompanies';
import type { Action, State } from '../types';

const intialState = {
  isFetching: false
};

export const awardCompanies = (state: State = intialState, action: Action) => {
  switch (action.type) {
    case actions.READ_AWARD_COMPANIES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.READ_AWARD_COMPANIES_SUCCESS:
      return {
        awardCompanies: action.payload.flat(1), //make one object array from multiple further arrays, using flat(1) function
        isFetching: false
      };
    case actions.READ_AWARD_COMPANIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
