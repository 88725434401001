// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI } from '../middleware';

export const READ_MEASURED_COMPANY_REQUEST = 'READ_MEASURED_COMPANY_REQUEST';
export const READ_MEASURED_COMPANY_SUCCESS = 'READ_MEASURED_COMPANY_SUCCESS';
export const READ_MEASURED_COMPANY_FAILURE = 'READ_MEASURED_COMPANY_FAILURE';

const loadMeasuredCompanyRequest = (): Action => {
  return {
    type: READ_MEASURED_COMPANY_REQUEST
  };
};

const loadMeasuredCompanySuccess = (measuredCompany): Action => {
  return {
    type: READ_MEASURED_COMPANY_SUCCESS,
    payload: measuredCompany
  };
};

const loadMeasuredCompanyFailure = (error): Action => {
  return {
    type: READ_MEASURED_COMPANY_FAILURE,
    error
  };
};

export const loadMeasuredCompany = (slug: string): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    dispatch(loadMeasuredCompanyRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `/wp-json/wp/v2/award-companies?slug=${slug}`
    });

    if (response instanceof Error) {
      throw response;
    }
    dispatch(loadMeasuredCompanySuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadMeasuredCompanyFailure(error));
    return error;
  }
};
