// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI } from '../middleware';

export const READ_HELPERS_REQUEST = 'READ_HELPERS_REQUEST';
export const READ_HELPERS_SUCCESS = 'READ_HELPERS_SUCCESS';
export const READ_HELPERS_FAILURE = 'READ_HELPERS_FAILURE';

const loadHelpersRequest = (): Action => {
  return {
    type: READ_HELPERS_REQUEST
  };
};

const loadHelpersSuccess = (helpers): Action => {
  return {
    type: READ_HELPERS_SUCCESS,
    payload: helpers
  };
};

const loadHelpersFailure = (error): Action => {
  return {
    type: READ_HELPERS_FAILURE,
    error
  };
};

export const loadHelpers = (
  year: string,
  month: string,
  award_industry: string,
  country: string
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    dispatch(loadHelpersRequest());
    const response = await callAPI({
      method: 'GET',
      endpoint: `/customer-satisfaction-awards/helpers/?year=${
        year ? year : ''
      }&month=${month ? month : ''}&award_industry=${
        award_industry ? award_industry : ''
      }&country=${country ? country : ''}`
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadHelpersSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadHelpersFailure(error));
    return error;
  }
};
