// @flow
import * as actions from '../actions/readPage';

const intialState = {
  isFetching: false,
  error: undefined,
  pages: {}
};

export const pages = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.READ_PAGE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.READ_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload && action.payload.slug]: action.payload
        },
        isFetching: false
      };
    case actions.READ_PAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
