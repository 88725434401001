// @flow
import * as React from 'react';
import { Image, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

type Props = {
  award: any,
  year: any,
  month: any,
  awardType: any,
  industry: any,
  index: any,
  country: string
};

class AwardTile extends React.Component<Props> {
  render() {
    const { award, year, month, awardType, industry, index } = this.props;
    let countryQuery =
      this.props.country === 'australia'
        ? ''
        : `/${this.props.country.replace('_', '-')}`;
    return (
      <Grid.Column mobile={6} tablet={4} computer={3} largeScreen={2}>
        {award &&
          award.awardWinners &&
          award.awardWinners[index] &&
          award.awardWinners[index].company.banner &&
          award.awardWinners[index].company.banner && (
            <Image src={award.awardWinners[index].company.banner} />
          )}
        <p className="award-tile__company">
          {award &&
            award.awardWinners &&
            award.awardWinners[index] &&
            award.awardWinners[index].company &&
            award.awardWinners[index].company.postTitle}
        </p>
        <p className="award-tile__award-type">
          {award && award.awardType && award.awardType.postTitle}
        </p>
        {month !== 'annual' && (
          <p className="award-tile__full-results">
            <Link
              to={
                '/full-results' +
                countryQuery +
                '?year=' +
                year +
                '&month=' +
                month +
                '&awardType=' +
                awardType +
                '&industry=' +
                industry
              }
            >
              Full Results &gt;
            </Link>
          </p>
        )}
      </Grid.Column>
    );
  }
}

export default AwardTile;
