//@flow
import React from 'react';
import {
  Grid,
  Container,
  Image,
  Select,
  Responsive,
  Segment,
} from 'semantic-ui-react';
import CountryMenu from '../components/CountryMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadAwards, loadHelpers, loadHomePageCms } from '../actions';
import PageBanner from '../components/PageBanner';
import LoadingIndicator from '../components/LoadingIndicator';
import AwardsList from '../components/AwardsList';
import NewsletterSignup from '../components/NewsletterSignup';
import type { State as AppState, Dispatch } from '../types';
import csaLogo from '../assets/images/logos/csa-logo.png';
import csaBanner from '../assets/images/csa-banner.png';

type Props = {
  years: any,
  months: any,
  loadAwards: Function,
  loadHelpers: Function,
  awards: any,
  updateUserPassword: Function,
  loadAwardsFetching: boolean,
  country: string,
  loadHomePageCms: Function,
  cmsContent: any,
  isCmsContentLoading: boolean,
};
type State = any;
class AwardListing extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: null,
      selectedMonth: null,
    };
  }

  componentDidMount() {
    var currentSelectedYear =
      this.state.selectedYearKey != null
        ? this.state.selectedYearKey
        : this.props.years && this.props.years[0];
    var currentSelectedMonth =
      this.state.selectedMonthKey != null
        ? this.state.selectedMonthKey
        : this.props.months &&
          this.convertForSelect(this.props.months).length &&
          this.convertForSelect(this.props.months)[0].value;
    this.props.loadAwards(
      currentSelectedYear,
      currentSelectedMonth,
      this.props.country
    );
    this.props.loadHelpers(null, null, null, this.props.country);
    window.scrollTo(0, 0);

    // Load Page Content from CMS
    this.props.loadHomePageCms();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.country !== prevProps.country ||
      this.props.months !== prevProps.months ||
      this.props.years !== prevProps.years
    ) {
      var currentSelectedYear =
        this.state.selectedYearKey != null
          ? this.state.selectedYearKey
          : this.props.years[0];
      var currentSelectedMonth =
        this.state.selectedMonthKey != null
          ? this.state.selectedMonthKey
          : this.props.months &&
            this.convertForSelect(this.props.months).length &&
            this.convertForSelect(this.props.months)[0].value;
      this.props.loadAwards(
        currentSelectedYear,
        currentSelectedMonth,
        this.props.country
      );
    }
  }

  handleUpdate = (formData) => {
    this.props.updateUserPassword(formData);
  };

  convertForSelect(keyValueArray) {
    var formattedArrayForSelect = [];
    for (var prop in keyValueArray) {
      formattedArrayForSelect.push({
        key: prop,
        value: prop,
        text: keyValueArray[prop],
      });
    }
    return formattedArrayForSelect;
  }

  handleYearSelectchange = function (e, data) {
    this.setState({ selectedYear: data.value });
    this.setState({ selectedYearKey: data.options[data.value].text });

    var currentSelectedYear = data.options[data.value].text;
    var currentSelectedMonth =
      this.state.selectedMonthKey != null
        ? this.state.selectedMonthKey
        : this.convertForSelect(this.props.months)[0].value;
    this.props.loadAwards(
      currentSelectedYear,
      currentSelectedMonth,
      this.props.country
    );
    this.props.loadHelpers(currentSelectedYear, null, null, this.props.country);
  };
  handleMonthSelectchange = function (e, data) {
    this.setState({ selectedMonth: data.value });
    this.setState({ selectedMonthKey: data.value });

    var currentSelectedYear =
      this.state.selectedYearKey != null
        ? this.state.selectedYearKey
        : this.props.years[0];
    var currentSelectedMonth = data.value;
    this.props.loadAwards(
      currentSelectedYear,
      currentSelectedMonth,
      this.props.country
    );
    this.props.loadHelpers(
      currentSelectedYear,
      currentSelectedMonth,
      null,
      this.props.country
    );
  };

  render() {
    const { awards, loadAwardsFetching, cmsContent, isCmsContentLoading } =
      this.props;
    const { selectedYear, selectedMonth } = this.state;
    var selectYear = this.convertForSelect(this.props.years);
    var selectMonth = this.convertForSelect(this.props.months);

    var currentSelectedYear =
      this.state.selectedYearKey != null
        ? this.state.selectedYearKey
        : this.props.years && this.props.years[0];
    var currentSelectedMonth =
      this.state.selectedMonthKey != null
        ? this.state.selectedMonthKey
        : this.props.months &&
          this.convertForSelect(this.props.months).length &&
          this.convertForSelect(this.props.months)[0].value;

    return (
      <div>
        <PageBanner
          title="Roy Morgan Customer Satisfaction Awards"
          backgroundUrl={csaBanner}
        />

        <div className="full-width background-blue">
          {!isCmsContentLoading && cmsContent && cmsContent.intro && (
            <div
              className="full-width-inner"
              dangerouslySetInnerHTML={{ __html: cmsContent.intro }}
            ></div>
          )}
        </div>

        <CountryMenu country={this.props.country} />
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={3} computer={3}>
                <Responsive
                  as={Segment}
                  minWidth={768}
                  className="transparent-segment"
                >
                  <div className="banner__wrapper award-listing">
                    <div className="banner">
                      <div className="banner__title">
                        {this.props.country.replace('_', ' ')} <br />{' '}
                        {currentSelectedMonth} {currentSelectedYear}
                      </div>
                      <Image src={csaLogo} className="banner__logo" />
                    </div>
                  </div>
                  <NewsletterSignup />
                </Responsive>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={9} computer={9}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={6} tablet={6} computer={3}>
                      {selectYear && selectYear.length > 0 && (
                        <Select
                          options={selectYear}
                          value={
                            selectedYear != null
                              ? selectedYear
                              : selectYear[0].value
                          }
                          onChange={this.handleYearSelectchange.bind(this)}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={3}>
                      {selectMonth && selectMonth.length > 0 && (
                        <Select
                          options={selectMonth}
                          value={
                            selectedMonth ? selectedMonth : selectMonth[0].value
                          }
                          onChange={this.handleMonthSelectchange.bind(this)}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="no-margin-or-padding-top">
                    <Grid.Column width={12}>
                      {awards && awards.awards && !loadAwardsFetching ? (
                        <AwardsList
                          awards={awards}
                          country={this.props.country}
                          currentSelectedMonth={currentSelectedMonth}
                          currentSelectedYear={currentSelectedYear}
                        />
                      ) : (
                        <LoadingIndicator />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={12} computer={12}>
                <Responsive
                  as={Segment}
                  maxWidth={767}
                  className="transparent-segment"
                >
                  <div className="banner__wrapper award-listing">
                    <div className="banner">
                      <div className="banner__title">
                        {this.props.country.replace('_', ' ')} <br />{' '}
                        {currentSelectedMonth} {currentSelectedYear}
                      </div>
                      <Image src={csaLogo} className="banner__logo" />
                    </div>
                  </div>
                  <NewsletterSignup />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  awards: state.awards,
  years: state.helpers.year,
  months: state.helpers.month,
  loadAwardsFetching: state.helpers.isFetching,
  cmsContent: state.cmsPageContent.home,
  isCmsContentLoading: state.cmsPageContent.isHomeFetching,
});

const mapDispatchToProps = (dispatch: Dispatch<*>) =>
  bindActionCreators(
    {
      loadAwards,
      loadHelpers,
      loadHomePageCms,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AwardListing);
