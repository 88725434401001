// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch } from '../types';
import { callAPI } from '../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const newsletterSignup = (
  name: string,
  email: string
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    const body = {
      Name: name,
      EmailAddress: email
    };

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/campaign/subscribers`,
      body,
      isLiveAPICall: true,
      useToken: false
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }
    return true;
  } catch (error) {
    Sentry.captureException(error);
    // console.log('newsletterSignup error', e);
    return false;
  }
};
