//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadAwardCompanies } from '../actions';
import PageBanner from '../components/PageBanner';
import type { State as AppState, Dispatch } from '../types';
import csaBanner from '../assets/images/csa-banner.png';

type Props = {
  awardCompanies: any,
  loadAwardCompanies: () => Promise<boolean>
};

class MeasuredCompanies extends React.Component<Props> {
  componentDidMount() {
    this.props.loadAwardCompanies();
    window.scrollTo(0, 0);
  }

  render() {
    const { awardCompanies } = this.props;
    return (
      <div>
        <PageBanner title="Measured Companies" backgroundUrl={csaBanner} />
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <h2>Companies Monitored for Customer Satisfaction Awards</h2>
                <ul className="measured-companies__list">
                  {awardCompanies &&
                    Object.keys(awardCompanies).map((item, index) => {
                      return (
                        <li
                          dangerouslySetInnerHTML={{
                            __html:
                              awardCompanies[index] &&
                              awardCompanies[index].title &&
                              awardCompanies[index].title.rendered
                          }}
                          key={index}
                        />
                      );
                    })}{' '}
                </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  awardCompanies: state.awardCompanies.awardCompanies
});

const mapDispatchToProps = (dispatch: Dispatch<*>) =>
  bindActionCreators(
    {
      loadAwardCompanies
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasuredCompanies);
