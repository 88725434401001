// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Container,
  Image,
  Select,
  Responsive,
  Segment,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadAwardDetails,
  loadHelpers,
  loadFullResultsPageCms,
} from '../actions';
import PageBanner from '../components/PageBanner';
import LoadingIndicator from '../components/LoadingIndicator';
import AwardDetails from '../components/AwardDetails';
import NewsletterSignup from '../components/NewsletterSignup';
import qs from 'qs';
import { convertForSelect } from '../helpers';

import type { State as AppState, Dispatch } from '../types';
import csaLogo from '../assets/images/logos/csa-logo.png';
import csaBanner from '../assets/images/csa-banner.png';

type Props = {
  loadAwardDetails: Function,
  loadHelpers: Function,
  country?: string,
  awardDetails: Array<any>,
  awardDetailsFetching: boolean,
  months: {
    [string]: string,
  },
  years: Array<string>,
  awardIndustries: {
    [number]: string,
  },
  awardTypes: {
    [number]: string,
  },
  country: string,
  loadFullResultsPageCms: Function,
  cmsContent: any,
  isCmsContentLoading: boolean,
};

type State = {
  selectedYear?: string,
  selectedMonth?: string,
  selectedAwardIndustry?: string,
  selectedAwardType?: string,
};

class FullResults extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    let queryStringParams = qs.parse(window.location.search.replace('?', ''));
    const { year, month, industry, awardType } = queryStringParams;

    this.setState(
      {
        selectedYear: year,
        selectedMonth: month,
        selectedAwardIndustry: industry,
        selectedAwardType: awardType,
      },
      () => this.loadAwardDetails()
    );
    // window.scrollTo(0, 0);

    this.props.loadFullResultsPageCms();
  }

  componentDidUpdate() {
    if (!this.state.selectedYear && this.props.years) {
      this.setState({ selectedYear: this.props.years[0] });
    }
    if (!this.state.selectedMonth && this.props.months) {
      this.setState({ selectedMonth: Object.keys(this.props.months)[0] });
    }
    if (!this.state.selectedAwardIndustry && this.props.awardIndustries) {
      this.setState({
        selectedAwardIndustry: Object.keys(this.props.awardIndustries)[0],
      });
    }
    if (!this.state.selectedAwardType && this.props.awardTypes) {
      this.setState({
        selectedAwardType: Object.keys(this.props.awardTypes)[0],
      });
    }

    if (
      this.state.selectedYear &&
      this.state.selectedMonth &&
      this.state.selectedAwardIndustry &&
      this.state.selectAwardType
    ) {
      loadAwardDetails();
    }
  }

  loadAwardDetails = async (newIndustry = false) => {
    await this.props.loadHelpers(
      this.state.selectedYear,
      this.state.selectedMonth,
      this.state.selectedAwardIndustry,
      this.props.country
    );

    let newAwardType = this.state.selectedAwardType;
    if (newIndustry === true) {
      const keys = Object.keys(this.props.awardTypes);
      newAwardType = keys[0];

      this.setState({ selectedAwardType: newAwardType });
    }

    this.props.loadAwardDetails(
      this.state.selectedYear,
      this.state.selectedMonth,
      this.props.country,
      newAwardType,
      this.state.selectedAwardIndustry
    );
  };

  handleSelectChange = (event, data) => {
    const name = data.name;

    let value = data.options.find((o) => o.value === data.value).text;
    if (name === 'selectedAwardIndustry') {
      this.setState(
        {
          [name]: data.value.toString(),
        },
        () => this.loadAwardDetails(true)
      );
    } else if (name === 'selectedAwardType') {
      this.setState(
        {
          [name]: data.value.toString(),
        },
        () => this.loadAwardDetails()
      );
    } else {
      this.setState(
        {
          [name]: value.toString().toLowerCase(),
        },
        () => this.loadAwardDetails(true)
      );
    }
  };

  render() {
    const {
      awardDetails,
      awardDetailsFetching,
      years,
      months,
      awardIndustries,
      awardTypes,
      cmsContent,
      isCmsContentLoading,
    } = this.props;

    const {
      selectedYear,
      selectedMonth,
      selectedAwardIndustry,
      selectedAwardType,
    } = this.state;

    const selectYear = convertForSelect(years);
    const selectMonth = convertForSelect(months);
    const selectAwardType = convertForSelect(awardTypes);
    const selectIndustry = convertForSelect(awardIndustries);

    let fontSize;

    if (selectIndustry.length > 0 && selectedAwardIndustry) {
      const industryPresent = selectIndustry.find(
        (o) => o.value === selectedAwardIndustry
      );
      if (industryPresent) {
        if (
          industryPresent &&
          industryPresent.text &&
          industryPresent.text.length &&
          industryPresent.text.length > 15
        ) {
          fontSize = '14px';
        } else {
          fontSize = '18px';
        }
      } else {
        fontSize = '18px';
      }
    } else {
      fontSize = '18px';
    }

    const selectAwardIndustriesStyle = {
      fontSize: fontSize,
    };

    let resultsComponent;

    if (awardDetailsFetching) {
      resultsComponent = <LoadingIndicator />;
    } else if (awardDetails && awardDetails.length && !awardDetails[0].awards) {
      // resultsComponent = <div> {awardDetails[0]} </div>;
      resultsComponent = (
        <div>
          Please select a sub-category from the dropdown list or return to 
          <a href="/">All Categories</a> to access desired industry results.
        </div>
      );
    } else {
      resultsComponent = awardDetails.length > 0 && (
        <AwardDetails awardDetails={awardDetails} />
      );
    }

    // Selected Values
    let selectedYearValue = '';
    if (selectYear.length > 0) {
      selectedYearValue = selectYear[0].value;
    }
    if (
      selectedYear &&
      selectYear.find((o) => o.text === selectedYear.toString())
    ) {
      const year = selectYear.find((o) => o.text === selectedYear.toString());
      selectedYearValue = year && year.value ? year.value : undefined;
    }

    let selectedMonthValue = '';
    if (selectMonth.length > 0) {
      selectedMonthValue = selectMonth[0].value;
    }
    if (
      selectedMonth &&
      selectMonth.find((o) => o.key === selectedMonth.toString())
    ) {
      const month = selectMonth.find((o) => o.key === selectedMonth.toString());
      selectedMonthValue = month && month.value ? month.value : undefined;
    }
    let selectedIndustryValue = '';
    if (selectIndustry.length > 0) {
      selectedIndustryValue = selectIndustry[0].value;
    }
    if (
      selectedAwardIndustry &&
      selectIndustry.find((o) => o.value === selectedAwardIndustry.toString())
    ) {
      const industry = selectIndustry.find(
        (o) => o.value === selectedAwardIndustry.toString()
      );
      selectedIndustryValue =
        industry && industry.value ? industry.value : undefined;
    }
    let selectedAwardValue = '';
    if (selectAwardType.length > 0) {
      selectedAwardValue = selectAwardType[0].value;
    }
    if (
      selectedAwardType &&
      selectAwardType.find((o) => o.value === selectedAwardType.toString())
    ) {
      const award = selectAwardType.find(
        (o) => o.value === selectedAwardType.toString()
      );
      selectedAwardValue = award && award.value ? award.value : undefined;
    }

    let bannerIndustry = '';
    if (
      selectedAwardIndustry &&
      selectIndustry.find((o) => o.value === selectedAwardIndustry.toString())
    ) {
      const industry = selectIndustry.find(
        (o) => o.value === selectedAwardIndustry.toString()
      );
      bannerIndustry = industry && industry.text ? industry.text : undefined;
    }

    let bannerType = '';
    if (
      selectedAwardType &&
      selectAwardType.find((o) => o.value === selectedAwardType.toString())
    ) {
      const award = selectAwardType.find(
        (o) => o.value === selectedAwardType.toString()
      );
      bannerType = award && award.text ? award.text : undefined;
    }

    let bannerYear = '';
    if (
      selectedYear &&
      selectYear.find((o) => o.text === selectedYear.toString())
    ) {
      const year = selectYear.find((o) => o.text === selectedYear.toString());
      bannerYear = year && year.text ? year.text : undefined;
    }

    return (
      <div>
        <PageBanner
          title="Roy Morgan Customer Satisfaction Awards"
          backgroundUrl={csaBanner}
        />
        <div className="full-width background-blue">
          {!isCmsContentLoading && cmsContent && cmsContent.intro && (
            <div
              className="full-width-inner"
              dangerouslySetInnerHTML={{ __html: cmsContent.intro }}
            ></div>
          )}
        </div>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={3} computer={3}>
                <Responsive
                  as={Segment}
                  minWidth={769}
                  className="transparent-segment"
                >
                  <div className="banner__wrapper full-results">
                    <div className="banner">
                      <div className="banner__title">
                        <div
                          className="banner__award-industry"
                          style={selectAwardIndustriesStyle}
                        >
                          {bannerIndustry}
                        </div>
                        <div className="banner__award-type">{bannerType}</div>
                        <div className="banner__award-date">
                          {selectedMonthValue} {bannerYear}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Image src={csaLogo} className="banner__logo" />
                  <NewsletterSignup />
                </Responsive>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={9} computer={9}>
                <Grid>
                  <Grid.Row className="filter-results">
                    <Grid.Column mobile={12} tablet={12} computer={12}>
                      <Link className="filter-results__back" to="/">
                        {'< Back to all categories'}
                      </Link>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={2}>
                      {selectYear && selectYear.length > 0 && (
                        <Select
                          options={selectYear}
                          value={selectedYearValue}
                          name="selectedYear"
                          onChange={this.handleSelectChange}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={3}>
                      {selectMonth && selectMonth.length > 0 && (
                        <Select
                          options={selectMonth}
                          value={selectedMonthValue}
                          name="selectedMonth"
                          onChange={this.handleSelectChange}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={3}>
                      {selectIndustry && selectIndustry.length > 0 && (
                        <Select
                          options={selectIndustry}
                          value={selectedIndustryValue}
                          name="selectedAwardIndustry"
                          onChange={this.handleSelectChange}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={4}>
                      {selectAwardType && selectAwardType.length > 0 && (
                        <Select
                          options={selectAwardType}
                          value={selectedAwardValue}
                          name="selectedAwardType"
                          onChange={this.handleSelectChange}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <h3 className="full-results__sub-header">
                        {selectedMonthValue && selectedMonthValue}{' '}
                        {bannerYear && bannerYear}
                        <br />
                        {bannerIndustry}
                      </h3>
                      <h2 className="full-results__header">{bannerType}</h2>
                      {/* Shows if no Matching Awards*/}
                      {resultsComponent && resultsComponent}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column mobile={12}>
                <Responsive
                  as={Segment}
                  maxWidth={768}
                  className="transparent-segment"
                >
                  <div className="banner__wrapper full-results">
                    <div className="banner">
                      <div className="banner__title">
                        <div
                          className="banner__award-industry"
                          style={selectAwardIndustriesStyle}
                        >
                          {bannerIndustry}
                        </div>
                        <div className="banner__award-type">{bannerType}</div>
                        <div className="banner__award-date">
                          {selectedMonthValue} {bannerYear}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Image src={csaLogo} className="banner__logo" />
                  <NewsletterSignup />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  awardDetails: state.awardDetails.awardDetails,
  years: state.helpers.year,
  months: state.helpers.month,
  awardIndustries: state.helpers.awardIndustries,
  awardDetailsFetching: state.awardDetails.isFetching,
  helpersFetching: state.helpers.isFetching,
  awardTypes: state.helpers.awardTypes,
  cmsContent: state.cmsPageContent.fullResults,
  isCmsContentLoading: state.cmsPageContent.isFullResultsFetching,
});

const mapDispatchToProps = (dispatch: Dispatch<*>) =>
  bindActionCreators(
    {
      loadAwardDetails,
      loadHelpers,
      loadFullResultsPageCms,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FullResults);
