// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { idByProduct } from '../helpers';
import { Route, Switch } from 'react-router-dom';
import AwardListing from './AwardListing';
import HowWeScore from './HowWeScore';
import FullResults from './FullResults';
import MeasuredCompany from './MeasuredCompany';
import MeasuredCompanies from './MeasuredCompanies';
import HowWeScoreNZ from './HowWeScoreNZ';
import {
  PrivateRoute,
  LogIn,
  LogOut,
  ForgotPassword,
  NoAccess,
} from 'roy-morgan-auth';

type Props = {
  history: {
    push: (url: string) => void,
  },
};

class RootContent extends React.Component<Props> {
  render() {
    return (
      <div>
        <Switch>
          {/* Authentication */}
          <Route
            exact={true}
            path="/login"
            render={(props) => <LogIn showRegisterLink={true} {...props} />}
          />

          <PrivateRoute exact={true} path="/logout" component={LogOut} />
          <Route
            path="/forgot-password"
            render={(props) => (
              <ForgotPassword productFamilyId={idByProduct['Helix 2.0']} />
            )}
          />
          <Route
            exact={true}
            path="/no-access"
            render={(props) => (
              <NoAccess
                productId={idByProduct['Helix 2.0']}
                productName={'Helix 2.0'}
              />
            )}
          />
          <Route
            exact={true}
            path="/"
            render={(props) => <AwardListing country={'australia'} />}
          />
          <Route
            exact={true}
            path="/new-zealand"
            render={(props) => <AwardListing country={'new_zealand'} />}
          />
          <Route
            exact={true}
            path="/indonesia"
            render={(props) => <AwardListing country={'indonesia'} />}
          />

          <Route
            exact={true}
            path="/full-results"
            render={(props) => <FullResults country={'australia'} />}
          />
          <Route
            exact={true}
            path="/full-results/new-zealand"
            render={(props) => <FullResults country={'new_zealand'} />}
          />
          <Route
            exact={true}
            path="/full-results/indonesia"
            render={(props) => <FullResults country={'indonesia'} />}
          />

          <Route
            exact={true}
            path="/how-we-score"
            render={(props) => <HowWeScore />}
          />
          <Route
            exact={true}
            path="/measured-companies"
            render={(props) => <MeasuredCompanies />}
          />
          <Route
            exact={true}
            path="/how-we-score-nz"
            render={(props) => <HowWeScoreNZ />}
          />

          <Route
            exact={true}
            path="/measured-companies/:slug/"
            component={MeasuredCompany}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

export default connect<Props, _, _, _, _, _>(
  mapStateToProps,
  null
)(RootContent);
