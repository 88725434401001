// @flow

export const productById = {
  '1': 'Live Management',
  '2': 'Audiences',
  '3': 'Search',
  '4': 'Live Mapping',
  '5': 'Helix Encoding',
  '6': 'Helix 2.0'
};

export const idByProduct = {
  'Live Management': 1,
  Audiences: 2,
  Search: 3,
  'Live Mapping': 4,
  'Helix Encoding': 5,
  'Helix 2.0': 6
};
