// @flow
import * as React from 'react';
import { Image, Grid, Responsive, Segment } from 'semantic-ui-react';

type Props = {
  awardWinner: any,
  count: any,
  equalWinner: any
};

class FullDetailsAwardWinnerRow extends React.Component<Props> {
  render() {
    const { awardWinner, count, equalWinner } = this.props;

    const memberProfileLink = awardWinner.company.memberProfile ? awardWinner.company.memberProfile : undefined
    return (
      <Grid.Row className={'full-results__row' + (equalWinner ? ' equal' : '')}>
        <Grid.Column mobile={12} tablet={2} computer={3}>
          <Responsive
            as={Segment}
            minWidth={769}
            className="transparent-segment full-results__first-column"
          >
            <div className="full-results__count">
              {!equalWinner && 'no.' + (count + 1)}
            </div>
            <div className="full-results__percentage">
              {count === 0 &&
                !equalWinner &&
                awardWinner.satisfactionScore &&
                '(' + awardWinner.satisfactionScore + '%)'}
            </div>
            <div className="full-results__arrows">
              {awardWinner.change === 'same' && <div className="arrow same" />}
              {awardWinner.change === 'up' && <div className="arrow up" />}
              {awardWinner.change === 'down' && <div className="arrow down" />}
            </div>
            <div className="full-results__company-name">
              {awardWinner.company.postTitle && awardWinner.company.postTitle}
            </div>
          </Responsive>
        </Grid.Column>
        <Grid.Column mobile={12} tablet={4} computer={4}>
          <Image
            src={awardWinner.company.banner}
            className="full-results__company-logo"
          />
        </Grid.Column>
        <Grid.Column
          mobile={12}
          tablet={2}
          computer={2}
          className="mobile-only"
        >
          {!equalWinner && (
            <div className="full-results__count mobile">no. {count + 1}</div>
          )}
          <div className="full-results__percentage mobile">
            {count === 0 &&
              !equalWinner &&
              awardWinner.satisfactionScore &&
              '(' + awardWinner.satisfactionScore + '%)'}
          </div>
          <div className="arrow-wrapper">
            {awardWinner.change === 'same' && <div className="arrow same " />}
            {awardWinner.change === 'up' && <div className="arrow up " />}
            {awardWinner.change === 'down' && <div className="arrow down " />}
          </div>
          <div className="full-results__company-name mobile">
            {awardWinner.company.postTitle && awardWinner.company.postTitle}
          </div>
        </Grid.Column>
        <Grid.Column mobile={12} tablet={6} computer={5}>
          {awardWinner.company.relatedReports && (
            <a
              href={awardWinner.company.relatedReports}
              target="_blank"
              rel="noopener noreferrer"
              className="full-results__buttons"
            >
              Related Reports
            </a>
          )}
          {memberProfileLink && (
            <a
              href={memberProfileLink}
              target="_blank"
              rel="noopener noreferrer"
              className="full-results__buttons"
            >
              Customer Profile
            </a>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default FullDetailsAwardWinnerRow;
