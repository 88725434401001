// @flow
import * as React from 'react';
import { NavMenu } from 'roy-morgan-header';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import rmrLiveLogo from '../assets/images/logos/rmr-logo.png';
import type { Dispatch } from '../types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

type Props = {
  authUser: any,
  location: any,
};

class Header extends React.Component<Props> {
  render() {
    const { authUser, location } = this.props;

    return (
      <div>
        <NavMenu
          logoUrl={rmrLiveLogo}
          authUser={authUser}
          publicNav={true}
          pathname={location.pathname}
          showLogin={false}
          mobileBreakpoint={950}
        >
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/"
              name="Customer Satisfaction Awards"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              exact
            />
            <Menu.Item
              as={NavLink}
              to="/full-results"
              name="Award Details"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
              exact
            />
            <Menu.Item
              as={NavLink}
              to="/how-we-score"
              name="How We Score"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
            />
            <Menu.Item
              as="a"
              href="https://live.roymorgan.com/contact-us"
              target="_blank"
              name="Contact Us"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
            />
          </Menu.Menu>
        </NavMenu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user.user,
    products: state.auth.products,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<*>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
