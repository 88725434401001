// @flow
import React from 'react';
import IndustryList from './IndustryList';

type Props = {
  awards: any,
  currentSelectedMonth: any,
  currentSelectedYear: any,
  country: string
};

class AwardsList extends React.PureComponent<Props> {
  render() {
    const { awards, currentSelectedYear, currentSelectedMonth } = this.props;
    return awards.awards.map((item, index) => {
      return (
        <IndustryList
          key={index}
          industries={item.awardIndustry}
          year={currentSelectedYear}
          month={currentSelectedMonth}
          country={this.props.country}
        />
      );
    });
  }
}

export default AwardsList;
