// @flow
import * as actions from '../actions/helpers';
import type { Action, State } from '../types';

const intialState = {
  isFetching: false
};

export const helpers = (state: State = intialState, action: Action) => {
  switch (action.type) {
    case actions.READ_HELPERS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.READ_HELPERS_SUCCESS:
      return {
        ...action.payload,
        isFetching: false
      };
    case actions.READ_HELPERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
