// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../middleware';

export const READ_PAGE_REQUEST = 'READ_PAGE_REQUEST';
export const READ_PAGE_SUCCESS = 'READ_PAGE_SUCCESS';
export const READ_PAGE_FAILURE = 'READ_PAGE_FAILURE';

const loadPageRequest = () => {
  return {
    type: READ_PAGE_REQUEST
  };
};

const loadPageSuccess = (page): any => {
  return {
    type: READ_PAGE_SUCCESS,
    payload: page
  };
};

const loadPageFailure = (error): any => {
  return {
    type: READ_PAGE_FAILURE,
    error
  };
};

export const loadPage = (slug: string): any => async (
  dispatch: any,
  getState
) => {
  try {
    dispatch(loadPageRequest());
    const response = await callAPI({
      method: 'GET',
      endpoint: `/wp-json/wp/v2/award_pages?slug=${slug}`,
      isLiveAPICall: false,
      useToken: false
    });

    dispatch(loadPageSuccess(response[0]));
    return response[0];
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadPageFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    return error;
  }
};
