// @flow
import { combineReducers } from 'redux';
import { reducer as authReducer } from 'roy-morgan-auth';
import { headerReducer } from 'roy-morgan-header';
import { awards } from './awards';
import { awardDetails } from './awardDetails';
import { awardCompanies } from './awardCompanies';
import { measuredCompany } from './measuredCompany';
import { cmsPageContent } from './cmsPageContent';
import { helpers } from './helpers';
import { pages } from './pages';

const rootReducer = combineReducers({
  auth: authReducer,
  header: headerReducer,
  awards,
  awardDetails,
  helpers,
  pages,
  awardCompanies,
  measuredCompany,
  cmsPageContent
});

export default rootReducer;
