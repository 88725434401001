// @flow
import * as React from 'react';
import AwardTile from './AwardTile';
import { Grid } from 'semantic-ui-react';
import { Fragment } from 'react';

type Props = {
  industryGroup: any,
  year: any,
  month: any,
  country: string
};

class IndustryGroup extends React.Component<Props> {
  render() {
    const { industryGroup, year, month } = this.props;
    return (
      <div className="industry-group">
        <h3 className="industry-group__header">
          {industryGroup &&
            industryGroup.industry &&
            industryGroup.industry.postTitle}
        </h3>
        <Grid>
          <Grid.Row>
            {/* Columns are all defined in the Award Tile Component*/}
            {industryGroup &&
              industryGroup.awards &&
              industryGroup.awards.map((award, index) => {
                return (
                  <Fragment key={index}>
                    <AwardTile
                      award={award}
                      year={year}
                      month={month}
                      awardType={award.awardType.id}
                      industry={industryGroup.industry.id}
                      country={this.props.country}
                      index="0"
                    />
                    {award.awardWinners.length > 1 &&
                      award.awardWinners[0].satisfactionScore ===
                        award.awardWinners[1].satisfactionScore && (
                        <AwardTile
                          key={index}
                          award={award}
                          year={year}
                          month={month}
                          awardType={award.awardType.id}
                          industry={industryGroup.industry.id}
                          country={this.props.country}
                          index="1"
                        />
                      )}
                  </Fragment>
                );
              })}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default IndustryGroup;
