// @flow
import * as React from 'react';
import IndustryGroup from './IndustryGroup';

import { Grid } from 'semantic-ui-react';

type Props = {
  industries: any,
  year: any,
  month: any,
  country: string
};

class IndustryList extends React.Component<Props> {
  render() {
    const { industries, year, month } = this.props;
    return (
      <Grid className="industry-listing">
        <Grid.Row>
          <Grid.Column width={12}>
            {industries &&
              Object.values(industries).map((industryGroup, index) => {
                return (
                  <IndustryGroup
                    key={index}
                    industryGroup={industryGroup}
                    year={year}
                    month={month}
                    // $FlowFixMe
                    industry={industryGroup.id}
                    country={this.props.country}
                  />
                );
              })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default IndustryList;
