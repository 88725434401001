// @flow
import * as React from 'react';
import { Provider, connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getAuthToken, getUserData } from 'roy-morgan-auth';
import type { Store, Dispatch } from '../types';
import Header from './Header';
import Footer from 'roy-morgan-footer';
import { BrowserRouter as Router } from 'react-router-dom';
import RootContent from './RootContent';

type Props = {
  store: Store<*, *>,
  getUserData: () => Promise<boolean>
};

class Root extends React.Component<Props> {
  componentDidMount() {
    const token = getAuthToken();
    if (token && token !== '0') {
      this.props.getUserData();
    }
  }

  render() {
    return (
      <div>
        <Provider store={this.props.store}>
          <div>
            <Router>
              <div>
                <Route path="/" component={Header} />
                <Route path="/" component={RootContent} />
                <Footer />
              </div>
            </Router>
          </div>
        </Provider>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<*>) => {
  return bindActionCreators(
    {
      getUserData
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(Root);
