//@flow
import React from 'react';
import { Grid, Container, List } from 'semantic-ui-react';

type Props = {
  country?: string,
  fullDetailsPage?: any
};

class CountryMenu extends React.Component<Props> {
  render() {
    const { country, fullDetailsPage } = this.props;

    return (
      <div className="country-menu__wrapper">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} />
              <Grid.Column mobile={12} tablet={9} computer={9}>
                <List>
                  <List.Item
                    className={country === 'australia' ? 'active' : ''}
                  >
                    <a href={fullDetailsPage ? '/full-results' : '/'}>
                      Australia
                    </a>
                  </List.Item>
                  <List.Item
                    className={country === 'new_zealand' ? 'active' : ''}
                  >
                    <a
                      href={
                        fullDetailsPage
                          ? '/full-results/new-zealand'
                          : '/new-zealand'
                      }
                    >
                      New Zealand
                    </a>
                  </List.Item>
                  {/*<List.Item className={country === "indonesia" ? "active" : ""}><a href={fullDetailsPage ? '/full-results/indonesia' : '/indonesia'}>Indonesia</a></List.Item>*/}
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default CountryMenu;
