// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI } from '../middleware';

// Home Page Constants
export const GET_CMS_HOME_CONTENT_REQUEST = 'GET_CMS_HOME_CONTENT_REQUEST';
export const GET_CMS_HOME_CONTENT_SUCCESS = 'GET_CMS_HOME_CONTENT_SUCCESS';
export const GET_CMS_HOME_CONTENT_FAILURE = 'GET_CMS_HOME_CONTENT_FAILURE';

// Full Results Page Constants
export const GET_CMS_FULL_CONTENT_REQUEST = 'GET_CMS_FULL_CONTENT_REQUEST';
export const GET_CMS_FULL_CONTENT_SUCCESS = 'GET_CMS_FULL_CONTENT_SUCCESS';
export const GET_CMS_FULL_CONTENT_FAILURE = 'GET_CMS_FULL_CONTENT_FAILURE';

// Home Page Types
const getCmsHomeContentRequest = (): Action => {
  return {
    type: GET_CMS_HOME_CONTENT_REQUEST,
  };
};

const getCmsHomeContentSuccess = (pageContent): Action => {
  return {
    type: GET_CMS_HOME_CONTENT_SUCCESS,
    payload: pageContent,
  };
};

const getCmsHomeContentFailure = (error): Action => {
  return {
    type: GET_CMS_HOME_CONTENT_FAILURE,
    error,
  };
};

//Full Results Page Types
const getCmsFullResultsContentRequest = (): Action => {
  return {
    type: GET_CMS_FULL_CONTENT_REQUEST,
  };
};

const getCmsFullResultsContentSuccess = (pageContent): Action => {
  return {
    type: GET_CMS_FULL_CONTENT_SUCCESS,
    payload: pageContent,
  };
};

const getCmsFullResultsContentFailure = (error): Action => {
  return {
    type: GET_CMS_FULL_CONTENT_FAILURE,
    error,
  };
};

export const loadHomePageCms =
  (): ThunkAction => async (dispatch: Dispatch, getState) => {
    try {
      dispatch(getCmsHomeContentRequest());

      const response = await callAPI({
        method: 'GET',
        endpoint: '/wp-json/csat/v1/home',
        isLiveAPICall: false,
      });

      if (response instanceof Error) {
        throw response;
      }

      const pageContent = {
        home: {
          intro: undefined,
        },
      };

      if (response && response.introTextContent) {
        pageContent.home.intro = response.introTextContent;
        dispatch(getCmsHomeContentSuccess(pageContent));
      }

      return pageContent;
    } catch (error) {
      Sentry.captureException(error);
      dispatch(getCmsHomeContentFailure(error));
      return error;
    }
  };

export const loadFullResultsPageCms =
  (): ThunkAction => async (dispatch: Dispatch, getState) => {
    try {
      dispatch(getCmsFullResultsContentRequest());

      const response = await callAPI({
        method: 'GET',
        endpoint: '/wp-json/csat/v1/full-results',
        isLiveAPICall: false,
      });

      if (response instanceof Error) {
        throw response;
      }

      const pageContent = {
        fullResults: {
          intro: undefined,
        },
      };

      if (response && response.introTextContent) {
        pageContent.fullResults.intro = response.introTextContent;
        dispatch(getCmsFullResultsContentSuccess(pageContent));
      }

      return pageContent;
    } catch (error) {
      Sentry.captureException(error);
      dispatch(getCmsFullResultsContentFailure(error));
      return error;
    }
  };
