// @flow
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import FullDetailsAwardWinnerRow from './FullDetailsAwardWinnerRow';

type Props = {
  awardDetails: Array<any>
};

class AwardDetails extends React.Component<Props> {
  render() {
    const { awardDetails } = this.props;

    let total = 0;
    if (awardDetails && awardDetails.length > 0 && awardDetails[0].awards) {
      for (let i = 0; i < awardDetails[0].awards.awardWinners.length; i++) {
        if (
          !isNaN(awardDetails[0].awards.awardWinners[i].sampleSize) &&
          awardDetails[0].awards.awardWinners[i].sampleSize !== ''
        ) {
          total += parseInt(awardDetails[0].awards.awardWinners[i].sampleSize);
        }
      }
    }

    return (
      <React.Fragment>
        {awardDetails &&
          awardDetails.length &&
          awardDetails[0].awards &&
          awardDetails[0].industry && (
            <div>
              <Grid>
                {awardDetails[0].awards.awardWinners &&
                  awardDetails[0].awards.awardWinners.map(
                    (item, index, awardDetails) => {
                      return (
                        <FullDetailsAwardWinnerRow
                          key={index}
                          count={index}
                          awardWinner={item}
                          equalWinner={
                            index > 0
                              ? awardDetails[index].satisfactionScore ===
                                  awardDetails[index - 1].satisfactionScore &&
                                awardDetails[index].satisfactionScore !== ''
                              : false
                          }
                        />
                      );
                    }
                  )}
              </Grid>
              <p>
                <span className="arrow up arrow-float-left" /> % of satisfied
                customers higher than last month.
              </p>
              <p>
                <span className="arrow down arrow-float-left" /> % of satisfied
                customers lower than last month.
              </p>
              <p>
                <span className="arrow same arrow-float-left" /> % of satisfied
                customers did not change last month.
              </p>
              {awardDetails[0].awards.timePeriod && (
                <p>
                  <strong>Time Period:</strong>
                  {awardDetails[0].awards.timePeriod}
                </p>
              )}
              {total !== 0 && (
                <p>
                  <strong>Sample Size:</strong> {total}
                </p>
              )}
              <p>
                <a href="/how-we-score">
                  How we score our Customer Satisfaction
                </a>
              </p>
            </div>
          )}
      </React.Fragment>
    );
  }
}

export default AwardDetails;
