//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadAwardCompanies } from '../actions';
import PageBanner from '../components/PageBanner';
import type { State as AppState, Dispatch } from '../types';
import csaBanner from '../assets/images/csa-banner.png';
import LoadingIndicator from '../components/LoadingIndicator';

type Props = {
  awardCompanies: any,
  loadAwardCompanies: () => Promise<boolean>
};

class MeasuredCompanies extends React.Component<Props> {
  componentDidMount() {
    this.props.loadAwardCompanies();
    window.scrollTo(0, 0);
  }

  render() {
    const { awardCompanies } = this.props;
    // sort award companies alphabetically
    let sortedAwardCompanies =
      awardCompanies &&
      awardCompanies.sort((a, b) => {
        var a1 = a.title.rendered.toLowerCase();
        var b1 = b.title.rendered.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
    if (sortedAwardCompanies) {
      for (var i = 0; i < sortedAwardCompanies.length; i++) {
        var cur = sortedAwardCompanies[i];
        if (cur.acf.hideOnMeasuredCompaniesPage) {
          sortedAwardCompanies.splice(i, 1);
          break;
        }
      }
    }
    if (!sortedAwardCompanies) {
      return <LoadingIndicator />;
    }
    return (
      <div>
        <PageBanner title="Measured Companies" backgroundUrl={csaBanner} />
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <h2>Companies Monitored for Customer Satisfaction Awards</h2>
                <ul className="measured-companies__list">
                  {sortedAwardCompanies &&
                    Object.keys(sortedAwardCompanies)
                      .sort()
                      .map((item, index) => {
                        return (
                          <li
                            dangerouslySetInnerHTML={{
                              __html:
                                sortedAwardCompanies[index] &&
                                sortedAwardCompanies[index].title &&
                                !sortedAwardCompanies[index].acf
                                  .hideOnMeasuredCompaniesPage &&
                                sortedAwardCompanies[index].title.rendered
                            }}
                            key={index}
                          />
                        );
                      })}{' '}
                </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  awardCompanies: state.awardCompanies.awardCompanies
});

const mapDispatchToProps = (dispatch: Dispatch<*>) =>
  bindActionCreators(
    {
      loadAwardCompanies
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasuredCompanies);
