//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadMeasuredCompany } from '../actions';
import PageBanner from '../components/PageBanner';
import type { State as AppState, Dispatch } from '../types';
import csaBanner from '../assets/images/csa-banner.png';

type Props = {
  match: any,
  measuredCompany: any,
  loadMeasuredCompany: Function
};

class MeasuredCompany extends React.Component<Props> {
  componentDidMount() {
    this.props.loadMeasuredCompany(this.props.match.params.slug);
    window.scrollTo(0, 0);
  }

  render() {
    const { measuredCompany } = this.props;
    return (
      <div>
        <PageBanner
          title={
            measuredCompany &&
            measuredCompany.length > 0 &&
            measuredCompany[0].acf &&
            measuredCompany[0].acf.displayName
          }
          backgroundUrl={csaBanner}
        />
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                {measuredCompany &&
                  measuredCompany.length > 0 &&
                  measuredCompany[0].acf && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: measuredCompany[0].acf.memberProfile
                      }}
                    />
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  measuredCompany: state.measuredCompany.measuredCompany
});

const mapDispatchToProps = (dispatch: Dispatch<*>) =>
  bindActionCreators(
    {
      loadMeasuredCompany
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasuredCompany);
