// @flow
import * as actions from '../actions/cmsPageContent';
import type { Action, State } from '../types';

const intialState = {
  isHomeFetching: false,
  isFullResultsFetching: false,
  home: { intro: undefined},
  fullResults: { intro: undefined},
};

export const cmsPageContent = (state: State = intialState, action: Action) => {
  switch (action.type) {
    case actions.GET_CMS_HOME_CONTENT_REQUEST:
      return {
        ...state,
        isHomeFetching: true
      };
    case actions.GET_CMS_HOME_CONTENT_SUCCESS:
      return {
        home: action.payload.home, //make one object array from multiple further arrays, using flat(1) function
        isHomeFetching: false,
      };
    case actions.GET_CMS_HOME_CONTENT_FAILURE:
      return {
        ...state,
        isHomeFetching: false,
        error: action.error,
      };
    case actions.GET_CMS_FULL_CONTENT_REQUEST:
      return {
        ...state,
        isFullResultsFetching: true,
      };
    case actions.GET_CMS_FULL_CONTENT_SUCCESS:
      return {
        fullResults: action.payload.fullResults, //make one object array from multiple further arrays, using flat(1) function
        isFullResultsFetching: false,
      };
    case actions.GET_CMS_FULL_CONTENT_FAILURE:
      return {
        ...state,
        isFullResultsFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
