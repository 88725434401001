// @flow

import * as React from 'react';
import { Loader } from 'semantic-ui-react';

const LoadingIndicator = () => (
  <div className="loading-indicator">
    <Loader active inline="centered" />
  </div>
);

export default LoadingIndicator;
