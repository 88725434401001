// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI } from '../middleware';

export const READ_AWARD_COMPANIES_REQUEST = 'READ_AWARD_COMPANIES_REQUEST';
export const READ_AWARD_COMPANIES_SUCCESS = 'READ_AWARD_COMPANIES_SUCCESS';
export const READ_AWARD_COMPANIES_FAILURE = 'READ_AWARD_COMPANIES_FAILURE';
export let PAGE_NO = 1;
export let All_COMPANIES = [];

const loadAwardCompaniesRequest = (): Action => {
  return {
    type: READ_AWARD_COMPANIES_REQUEST
  };
};

const loadAwardCompaniesSuccess = (awardCompanies): Action => {
  return {
    type: READ_AWARD_COMPANIES_SUCCESS,
    payload: awardCompanies
  };
};

const loadAwardCompaniesFailure = (error): Action => {
  return {
    type: READ_AWARD_COMPANIES_FAILURE,
    error
  };
};

export const loadAwardCompanies = (PAGE_NO?: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    dispatch(loadAwardCompaniesRequest());

    // As per `wp-json/wp/v2/award-companies` API - "per_page must be between 1 (inclusive) and 100 (inclusive)"
    let RECORDS_PER_PAGE = 100;

    // if PAGE_NO is not there, then set page to 1
    PAGE_NO = PAGE_NO ? PAGE_NO : 1;

    const response = await callAPI({
      method: 'GET',
      endpoint:
        `/wp-json/wp/v2/award-companies?per_page=` +
        RECORDS_PER_PAGE +
        '&page=' +
        PAGE_NO
    });

    if (response instanceof Error) {
      throw response;
    }

    All_COMPANIES.push(response);
    // Recursive condition break when response is less than the set records per page records
    if (response.length === RECORDS_PER_PAGE) {
      PAGE_NO = PAGE_NO + 1;
      // need to make a recurive call, as we have to show more than 100 companies and the API allows us to show maximum of 100 records on ONE page
      dispatch(loadAwardCompanies(PAGE_NO));
    } else {
      dispatch(loadAwardCompaniesSuccess(All_COMPANIES));
    }

    return All_COMPANIES;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadAwardCompaniesFailure(error));
    return error;
  }
};
