// @flow
export * from './actions';
export * from './products';

export const noCamelize = (key: string, convert: (k: string) => string) => {
  if (key.toLowerCase() === 'id') {
    return 'id';
  }
  return /(^[A-Z0-9_]+$|^id+$)/.test(key) ? key : convert(key);
};

export const slugify = (text: string) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

export const convertForSelect = (keyValueArray: Object) => {
  let formattedArrayForSelect = [];
  for (let prop in keyValueArray) {
    formattedArrayForSelect.push({
      key: prop,
      value: prop,
      text: keyValueArray[prop]
    });
  }
  return formattedArrayForSelect;
};
