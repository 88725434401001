//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageBanner from '../components/PageBanner';
import type { State as AppState, Dispatch } from '../types';
import csaBanner from '../assets/images/csa-banner.png';
import { loadPage } from '../actions/readPage';
import LoadingIndicator from '../components/LoadingIndicator';

type Props = {
  page: ?Object,
  isFetching: boolean,
  loadPage: (slug: string) => Promise<*>
};

class HowWeScore extends React.Component<Props> {
  componentDidMount() {
    this.props.loadPage('how-we-score');
    window.scrollTo(0, 0);
  }

  render() {
    const { isFetching, page } = this.props;
    if (isFetching || !page) {
      return <LoadingIndicator />;
    }
    return (
      <div className="how-we-score__page">
        <PageBanner
          title={page && page.title.rendered}
          backgroundUrl={csaBanner}
        />
        <Container>
          <Grid dangerouslySetInnerHTML={{
                              __html:
                                page && page.content && page.content.rendered
                            }}>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  page: state.pages.pages['how-we-score']
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadPage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HowWeScore);
