// @flow
import * as actions from '../actions/awardDetails';
import type { Action, State } from '../types';

const intialState = {
  isFetching: false,
  awardDetails: []
};

export const awardDetails = (state: State = intialState, action: Action) => {
  switch (action.type) {
    case actions.LOAD_AWARD_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.LOAD_AWARD_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case actions.LOAD_AWARD_DETAILS_SUCCESS:
      return {
        ...state,
        awardDetails: action.payload,
        isFetching: false
      };
    default:
      return state;
  }
};
