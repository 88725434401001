// @flow
import 'react-app-polyfill/ie11'; // For IE 9-11 support
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Root from './containers/Root';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import 'roy-morgan-base-theme/dist/semantic.min.css';
import './assets/css/index.css';
import type { Store } from 'redux';

if (process.env.NODE_ENV === 'production') {
  const env = process.env.REACT_APP_RM_ENV || '';
  const release = process.env.REACT_APP_RELEASE_VER || '';

  Sentry.init({
    dsn: 'https://e2978270c8024b83badaef2b94ce917f@o379043.ingest.sentry.io/5222438',
    environment: env,
    release,
  });
}

// $FlowFixMe
const store: Store = configureStore();

ReactDOM.render(
  <Root store={store} />,
  // $FlowFixMe
  document.getElementById('root')
);

registerServiceWorker();
